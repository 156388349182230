<div
  class="w-[32rem] bg-white ring-2 ring-inset ring-gray-200 rounded-md flex flex-col"
>
  <div class="flex justify-start gap-8 px-5 py-5">
    <div class="flex w-1/3">
      <img
        src="/assets/images/onboarding/chrome-prompt.png"
        class="w-full h-full object-cover aspect-square"
      />
    </div>
    <div class="w-2/3 flex flex-col justify-center gap-4">
      <div class="flex flex-col justify-center">
        <div class="text-lg font-semibold">Confect for Chrome</div>
        <!-- <div class="text-sm text-gray-500">Onboarding Manager</div> -->
      </div>
      <div class="px-2 py-3 text-sm rounded-md">
        To use multiple designs in the same catalog, you need to install the
        Confect Chrome Plugin.
      </div>
    </div>
  </div>
  <div class="flex border-t-2 divide-x-2 justify-center">
    <div class="px-5 py-3 flex items-center justify-center w-full">
      <a
        href="https://chromewebstore.google.com/detail/confectio-design-your-cat/leknliicffobjlhilhmijnbmmjfigjme?hl=en&pli=1"
        class="w-full flex items-center justify-center"
        target="_blank"
        ><confect-icon
          icon="download_to"
          iconClass="text-3xl mr-1 text-gray-300"
          class="text-lg font-semibold"
          >Add to Chrome - It’s free</confect-icon
        ></a
      >
    </div>
  </div>
</div>
